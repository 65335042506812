import axios from 'axios';
import React, { useEffect } from 'react';
import { useHistory, useLocation } from 'react-router-dom';
import { NodePOSTApi } from '../../../Constant/api';
import { slugs } from '../../../Constant/slugs';

export default function PaymentVerification(props) {
  const location = useLocation()
  const history = useHistory()

  const [reload, setReload] = React.useState(false)
  const [responseReturn, setResponseReturn] = React.useState(true)

  const TESTSuccess = () => {
    // const pid = localStorage.getItem('pid')
    // const rid = location.search.slice(location.search.search("refId") + 6)
    // const Amount = location.search.slice(location.search.search("amt") + 4, location.search.search("refId") - 1)
    const ExamID = location.pathname.slice(14)
    const Buy = location.pathname.slice(14)

    setReload(true)
    const pid = localStorage.getItem("pid")
    // localStorage.setItem("signature", `${res2.data}`)
    const Amount =  localStorage.getItem("AmountPaid")

    NodePOSTApi({
      pid: pid,
      rid: pid,
      Amount: `${Amount}.0`,
      examID: ExamID,
      buy: "true"
      // buy: location.search.slice(location.search.search("buy") + 4, location.search.search("exam") - 1)
    }, "esewa_payment_verification")
      .then((res) => {
        console.log('Success:', res.data);
        setResponseReturn(res.data.success)
        setReload(false)
        if (res.data.success) {
          history.push(`${slugs.exam_payment}/${location.search.slice(location.search.search("exam") + 5, location.search.search("data") - 1)}`)
        }
      })
      .catch((error) => {
        console.error('Error:', error);
      }).finally(() => {
        setReload(false)
      })
  }
  useEffect(() => {
    // if (
    //   location.search.search('refId') !== -1 &&
    //   location.search.search('oid') !== -1 &&
    //   location.search.search('amt') !== -1 &&
    //   location.search.search('buy') !== -1
    // )
    TESTSuccess()
  }, [])
  return (
    <div>
      {/* {location.search.slice(location.search.search("buy") + 4, location.search.search("exam") - 1)} */}
      {!responseReturn &&
        "Contact Admin for further process"

      }
      {reload &&
        <div className="modal" style={{ padding: "50vw" }}>
          <div className="dot-pulse"></div>
        </div>
      }
    </div>
  )
}